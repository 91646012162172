<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex flex-wrap">
          <div class="xl:col-5 col-12">
            <Panel header="Permission for companies">
              <MultiSelect
                v-model="selectedCompanies" :options="companies" optionLabel="name" optionValue="id"
                placeholder="Select Countries" :filter="true" :style="{width: '100%'}"
                id="selected-companies" class="multiselect-custom"
                @change="saveCompanies"
              ></MultiSelect>
            </Panel>
          </div>
          <div class="xl:col-7 col-12">
            <Panel header="Edit Payment Method">
              <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">

                <!-- API Key -->
                <div class="field pb-2 pt-2">
                  <div class="p-float-label">
                    <InputText id="api-key" v-model="v$.form.apiKey.$model" :class="{'p-invalid':v$.form.apiKey.$invalid && submitted}"/>
                    <label for="api-key" :class="{'p-error':v$.form.apiKey.$invalid && submitted}">API Key</label>
                  </div>

                  <small v-if="(v$.form.apiKey.$invalid && submitted) || v$.form.apiKey.$pending.$response" class="p-error">
                    {{ v$.form.apiKey.required.$message.replace('Value', 'API Key') }}
                  </small>
                </div>

                <!-- Token -->
                <div class="field pb-2 pt-2">
                  <div class="p-float-label">
                    <InputText id="token" v-model="v$.form.token.$model" :class="{'p-invalid':v$.form.token.$invalid && submitted}"/>
                    <label for="token" :class="{'p-error':v$.form.token.$invalid && submitted}">Token</label>
                  </div>

                  <small v-if="(v$.form.token.$invalid && submitted) || v$.form.token.$pending.$response" class="p-error">
                    {{ v$.form.token.required.$message.replace('Value', 'Token') }}
                  </small>
                </div>

                <!-- Min amount($) -->
                <div class="field pb-2 pt-2">
                  <div class="p-float-label">
                    <InputText id="min-amount" v-model="v$.form.minAmount.$model" :class="{'p-invalid':v$.form.minAmount.$invalid && submitted}"/>
                    <label for="min-amount" :class="{'p-error':v$.form.minAmount.$invalid && submitted}">Min amount($)</label>
                  </div>

                  <small v-if="(v$.form.minAmount.$invalid && submitted) || v$.form.minAmount.$pending.$response" class="p-error">
                    {{ v$.form.minAmount.required.$message.replace('Value', 'Min amount') }}
                  </small>
                </div>

                <!-- Fee(%) -->
                <div class="field pb-2 pt-2">
                  <div class="p-float-label">
                    <InputText id="fee" v-model="v$.form.fee.$model" :class="{'p-invalid':v$.form.fee.$invalid && submitted}"/>
                    <label for="fee" :class="{'p-error':v$.form.fee.$invalid && submitted}">Fee(%)</label>
                  </div>

                  <small v-if="(v$.form.fee.$invalid && submitted) || v$.form.fee.$pending.$response" class="p-error">
                    {{ v$.form.fee.required.$message.replace('Value', 'Fee') }}
                  </small>
                </div>

                <Button type="submit" label="Submit" class="mt-2"/>
              </form>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dataTable from "@/mixins/dataTable";
import companyResource from "@http/CompanyResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import {goChyp} from "@http/PaymentMethodResource";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  setup: () => ({v$: useVuelidate()}),

  mixins: [dataTable],

  data() {
    return {
      selectedCompanies: [],
      companies: [],
      form: {
        apiKey: null,
        token: null,
        minAmount: null,
        fee: null,
      },
      submitted: false,
    }
  },

  validations() {
    return {
      form: {
        apiKey: {required},
        token: {required},
        minAmount: {required},
        fee: {required},
      }
    }
  },

  methods: {
    saveCompanies() {
      goChyp.savePermissionCompany({companies: this.selectedCompanies})
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Published'));
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      goChyp.update({
        api_key: this.form.apiKey,
        token: this.form.token,
        min_amount: this.form.minAmount,
        fee: this.form.fee,
      })
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Successfully!'));
          this.resetForm();
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },

    resetForm() {
      this.submitted = false;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      let companies = await companyResource.allCompanies();
      let form = await goChyp.get();
      let selectedCompanies = await goChyp.getPermissionCompany();
      next((vm) => {
        vm.companies = companies.data;
        vm.selectedCompanies = selectedCompanies.data;
        vm.form.apiKey = form.data.api_key;
        vm.form.token = form.data.token;
        vm.form.minAmount = form.data.min_amount;
        vm.form.fee = form.data.fee;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate() {
    await goChyp.get()
      .then(({data}) => {
        this.form.apiKey = data.api_key;
        this.form.token = data.token;
        this.form.minAmount = data.min_amount;
        this.form.fee = data.fee;
      })
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
</style>
